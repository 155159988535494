<template>
	<div>
		<b-row>
			<!-- 得分总览 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="ActivityIcon" size="18" />
						得分总览
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_1" class="w-100" style="height: 280px"></div>

						<div class="divider">
							<div class="divider-text">分项得分</div>
						</div>

						<b-row>
							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-primary">
									<feather-icon icon="CheckSquareIcon" size="18" />
								</b-avatar>
								<div class="mt-50">总分</div>
								<h3 class="font-digit my-0">565</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-danger">
									<feather-icon icon="WindIcon" size="18" />
								</b-avatar>
								<div class="mt-50">空气</div>
								<h3 class="font-digit my-0">95</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-success">
									<feather-icon icon="DropletIcon" size="18" />
								</b-avatar>
								<div class="mt-50">水</div>
								<h3 class="font-digit my-0">72</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-warning">
									<feather-icon icon="CoffeeIcon" size="21" />
								</b-avatar>
								<div class="mt-50">舒适</div>
								<h3 class="font-digit my-0">84</h3>
							</b-col>
						</b-row>

						<b-row class="mt-50">
							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-secondary">
									<feather-icon icon="DribbbleIcon" size="18" />
								</b-avatar>
								<div class="mt-50">健身</div>
								<h3 class="font-digit my-0">89</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-info">
									<feather-icon icon="BookOpenIcon" size="18" />
								</b-avatar>
								<div class="mt-50">人文</div>
								<h3 class="font-digit my-0">64</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-warning">
									<feather-icon icon="CompassIcon" size="18" />
								</b-avatar>
								<div class="mt-50">服务</div>
								<h3 class="font-digit my-0">75</h3>
							</b-col>

							<b-col class="text-center" cols="3">
								<b-avatar rounded size="36" variant="light-success">
									<feather-icon icon="Share2Icon" size="18" />
								</b-avatar>
								<div class="mt-50">提高与创新</div>
								<h3 class="font-digit my-0">86</h3>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 建筑模型 -->
			<b-col cols="6">
				<h1 class="font-title text-center">健康建筑运行管理平台</h1>
				<b-embed allowfullscreen class="rounded" scrolling="no" src="http://mmsvr.ccabrnzeb.com/?noCache=388a390952" style="height: calc(60vh - 3rem); min-height: 578px" type="iframe"></b-embed>
			</b-col>

			<!-- 标识状态 -->
			<b-col cols="3">
				<b-card style="height: 60vh; min-height: 620px">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="AwardIcon" size="18" />
						标识状态
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<b-row>
							<b-col class="text-center" cols="4">
								<b-img :src="require('@/assets/images/buildings/label_healthy_building.png')" fluid style="height: 7rem" />
							</b-col>
							<b-col class="text-center d-flex align-items-center" cols="8">
								<div>
									<h4 class="font-weight-bolder">健康建筑标识证书</h4>
									<small>Certificate of Healthy Building Label</small>
								</div>
							</b-col>
						</b-row>

						<b-row class="mt-1">
							<b-col cols="12">
								<h4>项目名称</h4>
							</b-col>
							<b-col class="mt-50" cols="6"
								>项目星级：
								<span class="h4 text-success">
									<span class="mr-1">&#9733;</span>
									<span class="mr-1">&#9733;</span>
									<span>&#9733;</span>
								</span>
							</b-col>
							<b-col class="mt-50" cols="6">证书编号：***********</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">项目概况</div>
						</div>

						<b-row>
							<b-col cols="6">项目面积：**** m<sup>2</sup></b-col>
							<b-col cols="6">项目类型：****</b-col>

							<b-col class="mt-50" cols="12"> 业主单位：****</b-col>
							<b-col class="mt-50" cols="12">运营单位：****</b-col>
							<b-col class="mt-50" cols="12">项目地址：****</b-col>
						</b-row>

						<div class="divider">
							<div class="divider-text">评价指标</div>
						</div>

						<b-row>
							<b-col cols="6">室内PM2.5年平均浓度</b-col>
							<b-col cols="6"
								><span class="h4 font-digit text-success">17.7</span>
								<small>&nbsp;g/m<sup>3</sup></small>
							</b-col>

							<b-col class="mt-50" cols="6">可感知的室内噪声级</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">32.9</span>
								<small>&nbsp;dB(A)</small>
							</b-col>

							<b-col class="mt-50" cols="6">室内甲醛浓度</b-col>
							<b-col class="mt-50" cols="6"
								><span class="h4 font-digit text-success">32.9</span>
								<small>&nbsp;mg/m<sup>3</sup></small>
							</b-col>

							<b-col class="mt-50" cols="6">生活饮用水菌落总数</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success"> 10</span>
								<small>&nbsp;个 (CFU/mL)</small>
							</b-col>

							<b-col class="mt-50" cols="6">健身场地面积</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success">880</span>
								<small>&nbsp;m<sup>2</sup></small>
							</b-col>

							<b-col class="mt-50" cols="6">室外交流场地面积</b-col>
							<b-col class="mt-50" cols="6">
								<span class="h4 font-digit text-success">1282</span>
								<small>&nbsp;m<sup>2</sup></small>
							</b-col>
						</b-row>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>

		<b-row>
			<!-- 空气 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="WindIcon" size="18" />
						空气
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<statistic-card-with-area-chart
							v-if="subscribersGained.analyticsData"
							:chart-data="subscribersGained.series"
							:statistic="kFormatter(subscribersGained.analyticsData.subscribers)"
							icon="WindIcon"
							statistic-title="PM2.5 (μg/m3)"
						/>
						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-primary" icon="CircleIcon" size="16" />
								<span>日平均浓度</span>
							</div>
							<span
								>35 <small>μg/m<sup>3</sup></small></span
							>
						</div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 水 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="DropletIcon" size="18" />
						水
					</b-card-title>

					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<statistic-card-with-area-chart
							v-if="quarterlySales.analyticsData"
							:chart-data="quarterlySales.series"
							:statistic="kFormatter(quarterlySales.analyticsData.sales)"
							color="warning"
							icon="DropletIcon"
							statistic-title="浊度 (NTU)"
						/>

						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-warning" icon="CircleIcon" size="16" />
								<span>平均值</span>
							</div>
							<span>0.6 <small>NTU</small></span>
						</div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 舒适 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="CoffeeIcon" size="18" />
						舒适
					</b-card-title>

					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div>
							<label>室内噪声级 <small>dB(A)</small></label>
							<b-progress class="progress-bar-primary" max="50" show-value striped value="32.9" variant="primary" />
						</div>

						<div>
							<label>照度 <small>lux</small></label>
							<b-progress class="progress-bar-warning" max="500" show-value value="425" variant="warning" />
						</div>

						<div>
							<label>热湿</label>
							<div id="project-detail-benchmarking-3" style="height: 4.5rem"></div>
						</div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 健身 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="DribbbleIcon" size="18" />
						健身
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<vue-apex-charts :options="earningsChart.chartOptions" :series="earningsChart.series" height="85%" />
						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-success" icon="CircleIcon" size="16" />
								<span>室外健身场地面积</span>
							</div>
							<span
								>216 <small>m<sup>2</sup></small></span
							>
						</div>
						<div class="d-flex justify-content-between">
							<div>
								<feather-icon class="mr-50 text-primary" icon="CircleIcon" size="16" />
								<span>室内健身空间面积</span>
							</div>
							<span
								>153 <small>m<sup>2</sup></small></span
							>
						</div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 人文 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="BookOpenIcon" size="18" />
						人文
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div class="d-flex align-items-center">
							<b-avatar class="mr-2" size="36" variant="light-success">
								<feather-icon icon="Share2Icon" size="18" />
							</b-avatar>
							<b-form-checkbox v-model="selected" class="custom-control-success" disabled value="A"> 室外交流场地 </b-form-checkbox>
						</div>

						<div class="d-flex align-items-center">
							<b-avatar class="mr-2" size="36" variant="light-danger">
								<feather-icon icon="GithubIcon" size="18" />
							</b-avatar>
							<b-form-checkbox v-model="selected" class="custom-control-danger" disabled value="B"> 儿童游乐场地 </b-form-checkbox>
						</div>

						<div class="d-flex align-items-center">
							<b-avatar class="mr-2" size="36" variant="light-warning">
								<feather-icon icon="LifeBuoyIcon" size="18" />
							</b-avatar>
							<b-form-checkbox v-model="selected" class="custom-control-warning" disabled value="C"> 老年人活动场地 </b-form-checkbox>
						</div>

						<div class="d-flex align-items-center">
							<b-avatar class="mr-2" size="36" variant="light-info">
								<feather-icon icon="ChromeIcon" size="18" />
							</b-avatar>
							<b-form-checkbox v-model="selected" class="custom-control-info" disabled value="D"> 公共服务食堂 </b-form-checkbox>
						</div>

						<div class="d-flex align-items-center">
							<b-avatar class="mr-2" size="36" variant="light-primary">
								<feather-icon icon="CommandIcon" size="18" />
							</b-avatar>
							<b-form-checkbox v-model="selected" class="custom-control-primary" disabled value="E"> 文化活动场地 </b-form-checkbox>
						</div>
					</b-card-text>
				</b-card>
			</b-col>

			<!-- 服务 -->
			<b-col cols="2">
				<b-card style="height: 30vh">
					<b-card-title class="font-title">
						<feather-icon class="mr-1" icon="CompassIcon" size="18" />
						服务
					</b-card-title>
					<b-card-text class="d-flex flex-column justify-content-between" style="height: calc(100% - 3rem)">
						<div id="chart_5" class="w-100" style="height: 50%"></div>
						<vue-apex-charts id="revenue-report-chart" :options="revenueReport.chartOptions" :series="revenueReport.series" height="50%" type="bar" />
					</b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	import { Bullet, Gauge, Liquid, Radar, RingProgress } from "@antv/g2plot";
	import StatisticCardWithAreaChart from "@/@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
	import { $themeColors } from "@themeConfig";
	import { kFormatter } from "@/@core/utils/filter";
	import { onMounted } from "@vue/composition-api";
	import VueApexCharts from "vue-apexcharts";

	export default {
		name: "Health",
		components: {
			StatisticCardWithAreaChart,
			VueApexCharts,
		},
		data() {
			return {
				selected: "",
				subscribersGained: {
					series: [
						{
							name: "Subscribers",
							data: [28, 40, 36, 32, 38, 44, 35],
						},
					],
					analyticsData: {
						subscribers: 26,
					},
				},
				quarterlySales: {
					series: [
						{
							name: "Sales",
							data: [0.3, 0.8, 0.5, 0.4, 0.7, 0.6],
						},
					],
					analyticsData: {
						sales: 0.8,
					},
				},
				gaugeOptions: {
					radius: 0.9,
					range: {
						color: "#30BF78",
					},
					indicator: {
						pointer: {
							style: {
								stroke: "#D0D0D0",
							},
						},
						pin: {
							style: {
								stroke: "#D0D0D0",
							},
						},
					},
					statistic: {
						content: {
							style: {
								fontSize: 16,
							},
							formatter: ({ percent }) => `得分: ${(percent * 100).toFixed(0)}`,
						},
					},
					gaugeStyle: {
						lineCap: "round",
					},
				},
				earningsChart: {
					series: [153, 216],
					chartOptions: {
						chart: {
							type: "donut",
							toolbar: {
								show: false,
							},
						},
						dataLabels: {
							enabled: false,
						},
						legend: {
							show: false,
							position: "bottom",
							labels: {
								colors: "#808695",
							},
						},

						labels: ["室内", "室外"],
						stroke: { width: 0 },

						plotOptions: {
							pie: {
								donut: {
									labels: {
										show: true,
										name: {
											offsetY: 20,
										},
										value: {
											offsetY: -20,
											color: "#808695",
										},
										total: {
											show: true,
											label: "总计",
											color: "#808695",
										},
									},
								},
							},
						},
					},
				},
				revenueReport: {
					series: [
						{
							name: "得分",
							data: [40, 17, 20, 10],
						},
					],
					chartOptions: {
						chart: {
							stacked: true,
							type: "bar",
							toolbar: { show: false },
						},
						grid: {
							yaxis: {
								lines: { show: false },
							},
						},
						xaxis: {
							categories: ["物业", "公示", "活动", "宣传"],
							labels: {
								style: {
									colors: "#808695",
								},
							},
							axisTicks: {
								show: false,
							},
							axisBorder: {
								show: false,
							},
						},

						legend: {
							show: false,
						},
						dataLabels: {
							enabled: false,
						},
						colors: [$themeColors.primary, $themeColors.warning],
						plotOptions: {
							bar: {
								borderRadius: 10,
							},
							distributed: true,
						},
						yaxis: {
							show: false,
							labels: {
								style: {
									colors: "#808695",
								},
							},
						},
					},
				},
			};
		},
		setup() {
			const bulletChart3Init = () => {
				const bulletPlot = new Bullet("project-detail-benchmarking-3", {
					theme: {
						colors10: Object.values($themeColors),
					},
					data: [
						{
							title: "PMV",
							ranges: [-3, 0],
							measures: [0.3],
							target: 0,
						},
					],
					measureField: "measures",
					rangeField: "ranges",
					targetField: "target",
					xField: "title",
					color: {
						range: ["l(0) 0:#00cfe8 1:#28c76f", "l(0) 0:#28c76f 1:#ea5455"],
						measure: "#1890FF",
						target: "#28c76f",
					},
					size: {
						range: 15,
						measure: 10,
						target: 30,
					},
					xAxis: {
						line: null,
					},
					yAxis: {},
					label: {
						target: {
							offsetX: -15,
							offsetY: -22,
							style: {
								fill: "#28c76f",
							},
						},
						measure: {
							style: {
								fill: "#1890FF",
							},
						},
					},
					// 自定义 legend
					legend: {
						custom: true,
						position: "bottom",
						itemName: {
							style: {
								fill: "#808695",
							},
						},
						items: [
							{
								value: "冷",
								name: "冷",
								marker: {
									style: { fill: "#00cfe8" },
								},
							},
							{
								value: "舒适",
								name: "舒适",
								marker: {
									style: { fill: "#28c76f" },
								},
							},
							{
								value: "热",
								name: "热",
								marker: {
									style: { fill: "#ea5455" },
								},
							},
							/* {
                          value: "实际值",
                          name: "实际值",
                          marker: {
                              symbol: "square",
                              style: { fill: "#1890FF" },
                          },
                      },
                      {
                          value: "舒适度目标值",
                          name: "舒适度目标值",
                          marker: {
                              symbol: "line",
                              style: { stroke: "#28c76f" },
                          },
                      },*/
						],
					},
				});

				bulletPlot.render();
			};

			onMounted(() => {
				bulletChart3Init();
			});
		},
		methods: {
			kFormatter,
			chart_init_1() {
				const data = [
					{ name: "空气", star: 95 },
					{ name: "水", star: 72 },
					{ name: "舒适", star: 84 },
					{ name: "健身", star: 89 },
					{ name: "人文", star: 64 },
					{ name: "服务", star: 75 },
					{ name: "提高与创新", star: 86 },
				];
				const radarPlot = new Radar("chart_1", {
					data: data.map((d) => ({ ...d, star: Math.sqrt(d.star) })),
					xField: "name",
					yField: "star",
					appendPadding: [0, 10, 0, 10],
					meta: {
						star: {
							alias: "得分",
							min: 0,
							nice: true,
						},
					},
					xAxis: {
						tickLine: null,
					},
					yAxis: {
						label: false,
						grid: {
							alternateColor: "rgba(0, 0, 0, 0.04)",
						},
					},
					// 开启辅助点
					point: {
						size: 2,
					},
					area: {},
				});
				radarPlot.render();
			},
			chart_init_2() {
				const gauge = new Gauge("chart_2", {
					percent: 0.65,
					...this.gaugeOptions,
				});

				gauge.render();
			},
			chart_init_3() {
				const ringProgress = new RingProgress("chart_3", {
					autoFit: true,
					percent: 0.8,
					color: ["#F4664A", "#E8EDF3"],
				});

				ringProgress.render();
			},
			chart_init_4() {
				const liquidPlot = new Liquid("chart_4", {
					percent: 0.25,
					outline: {
						border: 4,
						distance: 1,
					},
					wave: {
						length: 128,
					},
				});
				liquidPlot.render();
			},
			chart_init_5() {
				const gauge = new Gauge("chart_5", {
					percent: 0.85,
					type: "meter",
					innerRadius: 0.8,
					range: {
						ticks: [0, 1 / 3, 2 / 3, 1],
						color: ["#F4664A", "#FAAD14", "#30BF78"],
					},
					indicator: {
						pointer: {
							style: {
								stroke: "#D0D0D0",
							},
						},
						pin: {
							style: {
								stroke: "#D0D0D0",
							},
						},
					},
					statistic: {
						content: {
							offsetY: 14,
							style: {
								fontSize: "14px",
								lineHeight: "14px",
								color: "#808695",
							},
						},
					},
				});

				gauge.render();
			},
		},
		mounted() {
			this.chart_init_1();
			//this.chart_init_2();
			//this.chart_init_3();
			//this.chart_init_4();
			this.chart_init_5();
		},
	};
</script>
